import { useTranslation } from 'react-i18next';
import { UserGroupIcon } from '@heroicons/react/solid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { timeConvert } from '../../../../utils/timeConvert';
import AddInstructorToPage from './AddInstructorToPage';
import SetLiveSpeaker from './SetLiveSpeaker';
import Trash from '../../../../SharedComponents/Svg/Trash';
import Notification from '../../../../SharedComponents/Notification';
import toast from 'react-hot-toast';
import EditInstructorToPage from './EditInstructorToPage';

export default function InstructorsComponent(props) {
  const { speakers, languageId, languages } = props;
  const { t, i18n } = useTranslation();

  console.log('speakers', speakers);
  return (
    <>
      <div className="bg-white p-4 rounded shadow">
        <div
          className={`border-b-2 text-gray-500 flex space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'} py-2`}
        >
          <UserGroupIcon className="h-6" />
          <span className="font-bold">{t('Speakers')}</span>
        </div>

        {speakers.map((e) => {
          const instructorName = e.instructor.instructorLngs?.filter((x) => x.languageId === languageId)[0].name;
          const talkTitle = e.talkersLngs?.filter((x) => x.languageId === languageId)[0].title;
          return (
            <>
              <div className="flex space-x-2 mt-4 relative">
                <div>
                  <img
                    alt="speaker"
                    className="h-10 w-10 rounded-full"
                    src={`${process.env.REACT_APP_IMAGE_PATH}${e.instructor.imageUrl}`}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <span className="flex items-center justify-between w-full flex-col md:flex-row space-y-2 md:space-x-2">
                    <span>{instructorName}</span>
                    <div className="flex space-x-2">
                      <EditInstructorToPage speaker={e} />
                      <DeleteSpeaker speaker={e} />
                      <SetLiveSpeaker speaker={e} />
                    </div> 
                  </span>
                  <span>{talkTitle}</span>
                  <div className="flex space-x-2 text-xs">
                    <span>{timeConvert(e.timeFrom)}</span>
                    <span>{timeConvert(e.timeTo)}</span>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <div>
          <AddInstructorToPage languages={languages} />
        </div>
      </div>
    </>
  );
}

const DeleteSpeaker = (props) => {
  const { t, i18n } = useTranslation();
  const { speaker } = props;

  const deleteSpeaker = async (e) => {
    const { data } = await axios.post(`/Talkers/Delete`, e);
    return data;
  };
  const queryClient = useQueryClient();
  const deleteSpeakerMutation = useMutation(deleteSpeaker, {
    onSuccess: () => {
      toast(<Notification message={t('Deleted, Successfully')} />);
      queryClient.invalidateQueries('page');
    },
    onError: (data) => {
      console.log('data', data);
      toast(<Notification message={t('Deleted, Successfully')} />);
      queryClient.invalidateQueries('page');
    },
  });

  const onClick = () => {
    deleteSpeakerMutation.mutate({
      id: speaker.id,
    });
  };
  return (
    <>
      <button
        onClick={() => {
          onClick();
        }}
        type="button"
        className={`bg-red-100 text-red-800 hover:bg-red-200  p-1 rounded transition duration-300 ease-in-out`}
      >
        <Trash className="w-4 h-4 " />
        {/* <span>{t('Delete')} </span> */}
      </button>
    </>
  );
};
